import { Row, Text, useWindowSize } from "./ui/utils";

export default function CoinScreen() {
  const size = useWindowSize();
    return (
      <Row maxWidth="100vw" 
      height={size> 1000 ?"400px": "500px" }
      id="about"
    backgroundColor="#fff" 
      // backgroundImage="linear-gradient(#1f1144,#1f1144, #57BEF8)" 
      justify="center" align="center" paddingTop="825px">
          <Text alignSelf='center' textAlign="center" width="100vw"
           fontSize={size.width > 800 ?"22px": "16px"}
            wordSpacing={size.width > 800 ?"7px": "5px"}
            lineHeight={size.width > 800 ?"40px": "30px"}
            paddingTop="15px"
            paddingRight={size.width > 800 ?"70px": "10px"}
            paddingLeft={size.width > 800 ?"70px": "10px"}
            fontWeight="bold">
          LifeDeFied is an Asset Tokenization and Data Security Platform featuring a DeFi Stable Token with Blockchain Utility. We believe a majority of issues that are currently occuring within the Traditional and Centralized Financial System can be resolved through our decentralized platform as a service, where everyone can be the Custodian over their identity, information and physical or digital assets.
          </Text>
      </Row>
    );
  }