import { Column, Text, useWindowSize } from "./ui/utils";
import { Chrono } from "react-chrono";

export default function Timeline() {
  const size = useWindowSize();
  const items = [
    {
      title: "PHASE ONE",
      cardTitle: "\n\tPHASE 1 BETA\n\n",
      cardSubtitle:
        "\t- Whitelisting Custodians \n \t- Small Capital Raise\n \t-Public PoS Blockchain \n \t- Stable Token Fundraiser\n \t- Whitelist Open TestNet",

    },
    {
      title: "PHASE TWO",
      cardTitle: "\n\tPHASE 2 BETA\n\n",
      cardSubtitle:
        "\t- Custodian Bounty Program\n \t- Mobile Utility Application\n \t- Custodian Identity Management\n \t- Asset and Data  Tokenization\n \t- Private PoS Blockchains",
    },
    {
      title: "PHASE THREE",
      cardTitle: "\n\tPHASE 3 BETA\n\n",
      cardSubtitle:
        "\t- Oracle Data Integrations\n \t- Tokenized Marketplace\n \t- Business Custodian Accounts\n \t- Government Custodian Accounts\n \t- PoS Blockchain Integrations", 
    },
    {
      title: "PHASE FOUR",
      cardTitle: "\n\tPHASE 4 BETA\n\n",
      cardSubtitle:
        "\t- Custodian Monetization\n \t- Cold Storage Solutions\n \t- Cold Storage Savings\n \t- Carbon Neutral/Negative\n \t- Nested PoS Virtulization\n \t- AI Based Smart Contracts",
    },
  ];

  return (
    <Column backgroundColor="#fff" id='roadmap' paddingTop='0px' paddingBottom='70px'>
      <Text 
      fontSize={size.width > 800 ?"40px": "30px"}
      textAlign="center" maxWidth='100vw' width='100%' fontWeight="bold" color='rgba(0,0,0,1)' marginBottom='50px'> Roadmap to Mainnet</Text>
      <div style={{whiteSpace: "pre-wrap" }}>
      <Chrono
        items={items}
        theme={{
          // primary: "rgba(0,0,0,0.7)",
          primary: "rgba(49,173,148,1)",
          // secondary: "rgba(255,255,255,0.7)",
          secondary: "rgba(41, 116, 143, 1)",
          // cardBgColor: "rgba(255,255,255,0.9)",
          cardBgColor: "rgba(239,239,239,1)",
          // cardForeColor: "rgba(0,0,0,0.8)",
          cardForeColor: "rgba(0,0,0,1)",
          titleColor: "rgba(49,173,148,1)",
          textColor: "rgba(255,255,255,1)",
        }}
        mode={size.width > 800 ? "VERTICAL_ALTERNATING" : "VERTICAL"}
        slideItemDuration={2000}
        scrollable={{ scrollbar: true }}
        showNavigation={false}
      />
      </div>
    </Column>
  );
}
