import { Column, Text, Row, useWindowSize } from "./ui/utils";
import { useState } from "react";

import { PlusIcon, MinusIcon } from "@radix-ui/react-icons";

export default function FAQ(){
    const size = useWindowSize();

    const faqList = [
        {
            question:'When is the Whitelisted Fundraiser?',
            answer:"The Stable Utility Token Fundraiser will be announced in Q1 2023. Whitelisted Custodians will receive specific updates and official announcements where they can participate in the fundraiser. After the fundraiser, the platform will launch a Bounty Program for the Open Public Testnet. Those who are wanting to particpate in the Open Testnet of the platform must be a Custodian and have acquired the Stable Utility Token",
            height: '230px',
        },{
            question:'Who is LifeDeFied for? ',
            answer:"LifeDeFied is for everyone, whether they be an Individual user, Business or Government level provider looking to make the switch over into DeFi and become their own Custodian. The platform is being developed on a decentralized Proof of Stake blockchain that allows it to act as a decentralized service utility for everyone around the globe will be able to access. ",
            height: '220px',
        },{
            question:'What is LifeDeFied for?',
            answer:"LifeDeFied is a public blockchain meant to bridge and secure the financial gaps between Traditional Finance (TradFi), Centralized Finance (CeFi), Decentralized Finance (DeFi) by offering disruptive and innovative services to its Custodians through its decentralized platform as a service. This again includes the Individual user ,Business or Government level provider.",
            height: '220px',
        },{
            question:'Where can I get Whitelisted', 
            answer:"Please navigate to the whitelisting form above and fill out the requested fields. We'll review your whitelisted submission and provide updates to you accordingly.",
            height: '160px',
        },{
            question:'How does stable tokenization work?', 
            answer:"The LifeDeFied platform communicates to the public layer of the blockchain where Custodians can further access either by their desktop or their mobile application and select what type of assets they want to upload whether they be physical, digital or documentational. After successful verification of ownership through API integrations and data oracles such as chainlinks DON (decentralized oracle network), the Custodian will receive an overview of their stable tokenized assets in the form of a smart contract. They will then be able to upload their stable tokens, NFTs or data into Cold Storage",
            height: '300px',
        },{
            question:'Why should I live my LifeDeFied?', 
            answer:"Why wouldn't you? You're the Custodian which empowers you to have complete sovereignty over your assets and personal information. The platform will allow the capability for the Custodian to connect to their own private blockchain networks where they will have an extra layer of economic freedom, security and opporitunity that the current financial system fails to provide. Additionally Custodians will have access to a DeFi P2P Marketplace called the BranchMarket. This Tokenized Marketplace will host addtional decentralized applications (dApps) providing a wide range of additional services and features",
            height: '300px',
        },{
            question:'When is the Public Mainnet?',
            answer:"The LifeDeFied Platform will be available as a decentralized service to the mass public in Q4 of 2023 where Custodians will be able to access Stable Asset Tokenization, Cold Storage Security Solutions, Decentralized Idenity Management and much, much more! ",
            height: '180px',
        }
    ];

    return (
        <Column backgroundColor="#EFEFEF" justify='center' align='center' id='faq' paddingBottom='70px'>
            <Text 
            fontSize={size.width > 600 ?"40px" : "30px" }
            textAlign='center'
            fontWeight="bold" color='rgba(0,0,0)' marginBottom='50px'  marginTop='70px'>Frequently Asked Questions</Text>
            {
                faqList.map((faq, index) => {
                    return (
                        <FAQTile key={index} question={faq.question} answer={faq.answer} height={faq.height} mobileHeight={faq.mobileHeight}/>
                    )
                })
        }
        </Column>
    )
}

function FAQTile(props){
    const size = useWindowSize();
    const [isOpen, setOPen] = useState(false);
    const [height, setHeight] = useState('70px');

    return(
        <Column 
        height={height}
        style={{
            transition: "height 0.3s",
            WebkitTransition: "height 0.3s",
        }}
        width={size.width > 1500 ? '60vw' : size.width > 700 ? '80vw' :'90vw' }
        // align='center'
        backgroundColor='rgba(255,255,255,1)' borderRadius='5px' marginBottom='12px'>
        <Row justify='space-between' margin='10px 20px'>
            <Text fontWeight='bold' letterSpacing='1.5px' 
            fontSize={size.width > 1000 ?'30px':'20px' }
            color='rgba(0,0,0,1)'>{props.question}</Text>
            {isOpen ? 
            <MinusIcon color='black' width='40px' height='40px' onClick={() => {
                setHeight('70px')
                setOPen(!isOpen)
                
            }}/> 
            : 
            <PlusIcon color='black' width='40px' height='40px' onClick={() => {
                setHeight(size.width<650 ? props.mobileHeight :props.height)
                setOPen(!isOpen)
                
            }}/>}
        </Row>
        {isOpen ? <Text 
        lineHeight={size.width > 1000 ?'30px':'22px' }
        fontWeight='600' 
        fontSize={size.width > 1000 ?'20px':'18px' }
        color='rgba(49, 173, 148, 1)' marginTop='10px' marginLeft='20px' marginRight='20px' marginBottom='20px'>{props.answer}</Text> : null}
        </Column>
    )
}