import { Row, Column, Text, useWindowSize } from "./ui/utils";
import { useState } from "react";
import styled from "styled-components";
import arnab from "../images/arnab.jpeg";
import keaton from "../images/Keaton.jpg";
import shawn from "../images/Shawn.jpg";
import rita from "../images/rita.jpg";
import jerry from "../images/jerry.jpg";

export default function Pioneers() {
  const size = useWindowSize();
  
  return (
    <Column
      id="pioneers"
      maxWidth="100vw"
      justify="center"
      align="center"
      paddingTop="0px"
      backgroundColor="#000"
    >
      <Text color="rgba(239, 239, 239, 1)" fontWeight="bold" 
      fontSize={size.width > 600 ?"40px" : "30px" } textAlign="center"
      marginBottom='40px' marginTop='70px'>Our Leadership Team</Text>
      {
        size.width > 1200 ?
        <>
      <Row maxWidth='80vw' width='100%' justify="space-between" marginLeft='190px' marginRight='190px'>
        <PioneerInfo url='https://www.linkedin.com/in/k3ytoshi/' image={keaton} name='Keaton McCune' title='Co-CEO | CTO' desc="Keaton is a diverse skilled technical leader with 7 years of hands-on, real-world experience bootstrapping platforms, marketplaces, tools, services, resources, development teams, and security teams for pre-seed, seed, or level series startups. Bonus XP involved being a Senior Full Stack WebApp/Mobile Developer specializing in the area of Decentralized Financial Security and Blockchain Web3 Technologies."/>
        <PioneerInfo url='https://www.linkedin.com/in/shawn-saucier/' image={shawn} name='Shawn Saucier' title='Co-CEO | CFO' desc="Shawn has extensive experience providing financial management, market research and strategic direction within technology companies.  His industry experience includes web 3 blockchain, software, SaaS, digital media, computer hardware, HDTV, cellular and home networking. Shawn has been involved with numerous early-stage hi-tech startups."/>
        <PioneerInfo url='https://www.linkedin.com/in/jerryyen/' image={jerry} name='Jerry Yen' title='CSO' desc="Jerry comes from an extraordinary technical background with 20+ years of experience being an executive leader growing revenues across B2B / B2C / B2G / enterprise fintech, SaaS, analytics, hardware, software, services, aerospace, training, sports, video, and e-commerce. They also specialize in strategic partnerships involved with DeFi and blockchain based technologies" />
       
      </Row>
      <Row maxWidth='60vw' width='100%'  justify="space-between" marginLeft='190px' marginRight='190px'>
        <PioneerInfo url='https://www.linkedin.com/in/rita-getsitdone/' image={rita} name='Rita Macdonald' title='CMO' desc="Rita is a versatile B2B and B2C marketing leader with end-to-end experience from developing product road maps to investor and analyst relations to strategic partnerships. Passionate about customer centric marketing and all things Crypto/Blockchain/NFTs. Storyteller focused on public relations, brand development, digital marketing, and customer focused messaging."/>
        <PioneerInfo url='https://www.linkedin.com/in/arnab-bhattacharya-ab7055189/' image={arnab} name='Arnab Bhattacharya' title='Senior Developer' desc="Arnab is a core developer having experience in developing scalable mobile and web applications for startups as well as big corporates. Having a bachelor's degree in Information Technology. Constantly learning, adapting, and innovating in the blockchain space by developing user-friendly scalable solutions."/>
     </Row>
     </>
      :
      size.width > 800 && size.width < 1200 ?
      <>
      <Row maxWidth='80vw' width='100%' justify="space-between" marginLeft='190px' marginRight='190px'>
      <PioneerInfo url='https://www.linkedin.com/in/k3ytoshi/' image={keaton} name='Keaton McCune' title='Co-CEO | CTO' desc="Keaton is a diverse skilled technical leader with 7 years of hands-on, real-world experience bootstrapping platforms, marketplaces, tools, services, resources, development teams, and security teams for pre-seed, seed, or level series startups. Bonus XP involved being a Senior Full Stack WebApp/Mobile Developer specializing in the area of Decentralized Financial Security and Blockchain Web3 Technologies."/>
      <PioneerInfo url='https://www.linkedin.com/in/shawn-saucier/' image={shawn} name='Shawn Saucier' title='Co-CEO | CFO' desc="Shawn has extensive experience providing financial management, market research and strategic direction within technology companies.  His industry experience includes web 3 blockchain, software, SaaS, digital media, computer hardware, HDTV, cellular and home networking. Shawn has been involved with numerous early-stage hi-tech startups."/>
      </Row>
      <Row maxWidth='80vw' width='100%' justify="space-between" marginLeft='190px' marginRight='190px'>
      <PioneerInfo url='https://www.linkedin.com/in/jerryyen/' image={jerry} name='Jerry Yen' title='CSO' desc="Jerry comes from an extraordinary technical background with 20+ years of experience being an executive leader growing revenues across B2B / B2C / B2G / enterprise fintech, SaaS, analytics, hardware, software, services, aerospace, training, sports, video, and e-commerce. They also specialize in strategic partnerships involved with DeFi and blockchain based technologies" />
      <PioneerInfo url='https://www.linkedin.com/in/rita-getsitdone/' image={rita} name='Rita Macdonald' title='CMO' desc="Rita is a versatile B2B and B2C marketing leader with end-to-end experience from developing product road maps to investor and analyst relations to strategic partnerships. Passionate about customer centric marketing and all things Crypto/Blockchain/NFTs. Storyteller focused on public relations, brand development, digital marketing, and customer focused messaging."/>
      </Row>
      <Row maxWidth='80vw' width='100%' justify="space-between" marginLeft='190px' marginRight='190px'>
      <PioneerInfo url='https://www.linkedin.com/in/arnab-bhattacharya-ab7055189/' image={arnab} name='Arnab Bhattacharya' title='Senior Developer' desc="Arnab is a core developer having experience in developing scalable mobile and web applications for startups as well as big corporates. Having a bachelor's degree in Information Technology. Constantly learning, adapting, and innovating in the blockchain space by developing user-friendly scalable solutions."/>
      </Row>
      </>
      :
      <>
      <PioneerInfo url='https://www.linkedin.com/in/k3ytoshi/' image={keaton} name='Keaton McCune' title='Co-CEO | CTO' desc="Keaton is a diverse skilled technical leader with 7 years of hands-on, real-world experience bootstrapping platforms, marketplaces, tools, services, resources, development teams, and security teams for pre-seed, seed, or level series startups. Bonus XP involved being a Senior Full Stack WebApp/Mobile Developer specializing in the area of Decentralized Financial Security and Blockchain Web3 Technologies."/>
      <PioneerInfo url='https://www.linkedin.com/in/shawn-saucier/' image={shawn} name='Shawn Saucier' title='Co-CEO | CFO' desc="Shawn has extensive experience providing financial management, market research and strategic direction within technology companies.  His industry experience includes web 3 blockchain, software, SaaS, digital media, computer hardware, HDTV, cellular and home networking. Shawn has been involved with numerous early-stage hi-tech startups."/>
      <PioneerInfo url='https://www.linkedin.com/in/jerryyen/' image={jerry} name='Jerry Yen' title='CSO' desc="Jerry comes from an extraordinary technical background with 20+ years of experience being an executive leader growing revenues across B2B / B2C / B2G / enterprise fintech, SaaS, analytics, hardware, software, services, aerospace, training, sports, video, and e-commerce. They also specialize in strategic partnerships involved with DeFi and blockchain based technologies" />
      <PioneerInfo url='https://www.linkedin.com/in/rita-getsitdone/' image={rita} name='Rita Macdonald' title='CMO' desc="Rita is a versatile B2B and B2C marketing leader with end-to-end experience from developing product road maps to investor and analyst relations to strategic partnerships. Passionate about customer centric marketing and all things Crypto/Blockchain/NFTs. Storyteller focused on public relations, brand development, digital marketing, and customer focused messaging."/>
      <PioneerInfo url='https://www.linkedin.com/in/arnab-bhattacharya-ab7055189/' image={arnab} name='Arnab Bhattacharya' title='Senior Developer' desc="Arnab is a core developer having experience in developing scalable mobile and web applications for startups as well as big corporates. Having a bachelor's degree in Information Technology. Constantly learning, adapting, and innovating in the blockchain space by developing user-friendly scalable solutions."/>
      </>
      }
    </Column>
  );
}

function PioneerInfo (props){
  const size = useWindowSize();
  const [show, setShow] = useState(false);
  

  return (
    <a style={{
      textDecoration: 'none'
    }} href={props.url}  rel="noopener noreferrer" target="_blank">
    <Card
      width={size.width}
    onMouseOver={() => setShow(true)}
    onMouseOut={() => setShow(false)}
  >
    <Column justify="center" align="center">
      <CardImage image={props.image} />
      <Text fontSize="20px" color="rgba(0,0,0,1)">
        {props.name}
      </Text>
      <Text fontSize="17px" color="rgba(49, 173, 148, 1)" marginTop='5px'>
        {props.title}
      </Text>
    </Column>
    {show && size.width > 750 ? 
    <div style={{
      minWidth:'270px'
    }}>
    <Text marginRight='10px' marginTop='50px' height="300px" align="center" justify="start" color="rgba(0,0,0)">
     {props.desc}
    </Text></div> : <div></div>}
  </Card>
  </a>
  )
}

const Card = styled.div`
  width: 250px;
  height: 270px;
  display: flex;
  -webkit-transition: width 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: width 0.3s; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0px) saturate(187%);
  box-shadow: 0 10px 20px 0 rgba(239, 239, 239, 1);
  -webkit-backdrop-filter: blur(0px) saturate(187%);
  background-color: rgba(239, 239, 239, 1);
  border-radius: 12px;
  border: 1px solid rgba(0,0,0,1);
  &:hover{
    width:  ${(props) => props.width > 800 ? '510px' : '250px'};
    cursor:pointer;
  }
`;

const CardImage = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 15px;
  background-position: center;
  height: 150px;
  border-radius: 100%;
  width: 150px;
  scale: 1;
`;
